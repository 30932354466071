import * as React from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import {
  AdvancedListContent,
  ChoiceFieldConfiguration,
  DateFieldConfiguration,
  FieldConfiguration,
  NumberFieldConfiguration,
} from "@amzn/ask-legal-domain";
import {
  FieldDefinition,
  FieldDefinitionDataType,
} from "@amzn/altar-sds-client";
import { NumberFieldEdit } from "./advanced-list/fields/NumberFieldEdit";
import { DateFieldEdit } from "./advanced-list/fields/DateFieldEdit";
import { BooleanFieldEdit } from "./advanced-list/fields/BooleanFieldEdit";
import { UIModel } from "../../../model/ui-model";
import { PersonFieldEdit } from "./advanced-list/fields/PersonFieldEdit";
import { ChoiceFieldEdit } from "./advanced-list/fields/ChoiceFieldEdit";
import { TextFieldEdit } from "./advanced-list/fields/TextFieldEdit";

export const UpdateFieldDefinition = (props: {
  state: UIModel.State<AdvancedListContent>;
  fieldDef: FieldDefinition;
  fieldConfiguration: FieldConfiguration;
  onUpdated?: (value: {
    fieldDef: FieldDefinition;
    fieldConfiguration?: FieldConfiguration;
  }) => void;
  onCancel?: () => void;
}) => {
  const determineContentBasedOnFieldType = () => {
    switch (props.fieldDef.dataType) {
      case FieldDefinitionDataType.boolean:
        return (
          <BooleanFieldEdit
            value={{
              fieldDef: props.fieldDef,
            }}
            onUpdated={(value) => {
              props.onUpdated?.(value);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.choice:
        return (
          <ChoiceFieldEdit
            value={{
              fieldDef: props.fieldDef,
              fieldConfiguration:
                props.fieldConfiguration as ChoiceFieldConfiguration,
            }}
            onUpdated={(value) => {
              props.onUpdated?.(value);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.date:
        return (
          <DateFieldEdit
            value={{
              fieldDef: props.fieldDef,
              fieldConfiguration:
                props.fieldConfiguration as DateFieldConfiguration,
            }}
            onUpdated={(value) => {
              props.onUpdated?.(value);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.number:
        return (
          <NumberFieldEdit
            value={{
              fieldDef: props.fieldDef,
              fieldConfiguration:
                props.fieldConfiguration as NumberFieldConfiguration,
            }}
            onUpdated={(value) => {
              props.onUpdated?.(value);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.IdentityRef:
        return (
          <PersonFieldEdit
            value={{
              fieldDef: props.fieldDef,
            }}
            onUpdated={(value) => {
              props.onUpdated?.(value);
            }}
            onCancel={props.onCancel}
          />
        );
      case FieldDefinitionDataType.string:
        return (
          <TextFieldEdit
            value={{
              fieldDef: props.fieldDef,
            }}
            onUpdated={(value) => {
              props.onUpdated?.(value);
            }}
            onCancel={props.onCancel}
          />
        );
      default:
        return (
          <>
            {props.fieldDef.dataType} is not supported yet! Please check back
            later!
          </>
        );
    }
  };

  return (
    <>
      <SpaceBetween direction="vertical" size="s">
        <h4>Edit Column</h4>
        <>{determineContentBasedOnFieldType()}</>
      </SpaceBetween>
    </>
  );
};
