import * as React from "react";
import { UIModel } from "../../../../model/ui-model";
import { Button, Input, Grid, TokenGroup, SpaceBetween, FormField } from "@amzn/awsui-components-react";
import {
  PrimitiveDataFactory,
} from "@amzn/ask-legal-domain";
import { ChoiceOption } from "@amzn/altar-sds-client";

const INPUT_LIMIT = 200;
const DUPLICATE_VALIDATION_MESSAGE = "Value already exists, please check below";
const CHAR_LIMIT_VALIDATION_MESSAGE = `Exceeded character limit of ${INPUT_LIMIT}`;

export const ChoiceOptionInput = (props: {
    state: UIModel.State<ChoiceOption[]>
}) => {
    const [inputText, setInputText] = React.useState<string>("");
    const [validationMessage, setValidationMessage] = React.useState<string>("");
    const getValidationResult = (t: string) => {
        if (t.length > INPUT_LIMIT) {
            return CHAR_LIMIT_VALIDATION_MESSAGE;
        }
        if (props.state.value?.find(o => o.displayValue === t)) {
            return DUPLICATE_VALIDATION_MESSAGE;
        }
    };

    React.useEffect(() => {
        setValidationMessage(getValidationResult(inputText));
    }, [inputText]);

    const onItemAdd = () => {
        if (!validationMessage) {
            props.state.setValue([
                ...props.state.value,
                {
                    displayValue: inputText, key: PrimitiveDataFactory.id()
                }
            ]);
            setInputText("");
        }
    };

    return (
        <>
            <SpaceBetween size="m">
                <Grid
                    gridDefinition={[
                        { colspan: 10 },
                        { colspan: 2 }
                    ]}
                >
                    <FormField errorText={validationMessage}>
                        <Input
                            stretch={true}
                            value={inputText}
                            onChange={({ detail }) => setInputText(detail.value)}
                            onKeyDown={({ detail }) => {
                                if (detail.keyCode === 13) {
                                    onItemAdd();
                                }
                            }}
                        />
                    </FormField>
                    <Button
                        disabled={validationMessage}
                        variant="icon" iconName="add-plus" onClick={onItemAdd}
                    />
                </Grid>
                {props.state.value.length > 0 && <TokenGroup
                    items={props.state.value
                        .map(o => ({label: o.displayValue, value: o.key}))
                    }
                    onDismiss={(e) => {
                        props.state.setValue([
                            ...props.state.value.slice(0, e.detail.itemIndex),
                            ...props.state.value.slice(e.detail.itemIndex + 1)
                        ]);
                    }}
                />}
            </SpaceBetween>
        </>
    );
};
