import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { Box, Button, Input, RadioGroup, Select, SpaceBetween, Toggle } from "@amzn/awsui-components-react";
import { UIField } from "../../common/UIField";
import {
  AdvancedListContent,
  CurrencyCode,
  DateFieldConfiguration,
  NumberFieldFormat,
  PrimitiveDataFactory,
  ChoiceFieldConfiguration,
  ChoiceFieldType
} from "@amzn/ask-legal-domain";
import { FieldDefinitionDataType, ChoiceOption } from "@amzn/altar-sds-client";
import { StaticListSelect } from "../../common/StaticListSelect";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import {
  AdvancedListConstants,
  NumberFormatUtil,
} from "../../../utils/advanced-list.constant";
import { NumberFieldConfiguration } from "@amzn/ask-legal-domain";
import { AdvancedListPolarisFactory } from "../../../factory/polaris/advanced-list-polaris-factory";
import { ChoiceOptionInput } from "../../advanced-list/fields/ChoiceFields/ChoiceOptionInput";

export const AddFieldDefinition = (props: {
  state: UIModel.State<AdvancedListContent>;
  onChange: () => void;
}) => {
  const [fieldDisplayName, setFieldDisplayName] = React.useState("");
  const [fieldDataType, setFieldDataType] =
    React.useState<FieldDefinitionDataType>();

  const [showOnLivePage, setShowOnLivePage] = React.useState<boolean>(true);

  // DataType based field configurations
  const [dateFieldConfig, setDateFieldConfig] =
    React.useState<DateFieldConfiguration>({
      dateFormat: AdvancedListConstants.DEFAULT_DATE_FORMAT,
    });

  // DataType based field configurations
  const [choiceFieldConfig, setChoiceFieldConfig] =
    React.useState<ChoiceFieldConfiguration>({
      type: ChoiceFieldType.SINGLE,
    });
  const [allowFreeTextChoiceOption, setAllowFreeTextChoiceOption] = React.useState<boolean>(false);


  // number field configurations
  const [numberFieldConfig, setNumberFieldConfig] = React.useState<
    NumberFieldConfiguration & { displayAsCurrency?: boolean }
  >({
    format: NumberFormatUtil.DEFAULT_NUMBER_FORMAT.format,
    displayAsCurrency: false,
  });
  const choiceOptionState = UIModel.State.use<ChoiceOption[]>({initialValue: []});

  const isValidFieldDefinition = () => {
    if (
      !fieldDisplayName?.trim() ||
      !fieldDataType ||
      (fieldDataType === FieldDefinitionDataType.date &&
        !dateFieldConfig.dateFormat?.trim()) ||
      (fieldDataType === FieldDefinitionDataType.number &&
        !numberFieldConfig.format?.toString().trim()) ||
      (fieldDataType === FieldDefinitionDataType.choice &&
        (!choiceFieldConfig || (
            choiceFieldConfig.type !== ChoiceFieldType.SINGLE &&
            choiceFieldConfig.type !== ChoiceFieldType.MULTIPLE
          )
        )
      )
    ) {
      return false;
    }
    return true;
  };

  const onAddFieldDefinition = () => {
    if (!isValidFieldDefinition()) return;
    props.state.setValue(
      AdvancedListContent.addField({
        content: props.state.value,
        fieldDefinition: {
          displayName: fieldDisplayName,
          dataType: fieldDataType,
          fieldKey: buildFieldKey(),
          choiceOptions: choiceOptionState.value,
          allowFreeTextOption: allowFreeTextChoiceOption
        },
        fieldConfiguration: buildFieldConfiguration(fieldDataType),
        fieldVisibility: showOnLivePage
      })
    );
    setFieldDisplayName("");
    setFieldDataType(null);
    props.onChange();
  };

  const buildFieldKey = () => {
    return PrimitiveDataFactory.id(4);
  };

  const buildFieldConfiguration = (fieldDataType: FieldDefinitionDataType) => {
    switch (fieldDataType) {
      case FieldDefinitionDataType.date:
        return dateFieldConfig;
      case FieldDefinitionDataType.number:
        return {
          format: numberFieldConfig.format,
          ...(numberFieldConfig.currency && {
            currency: numberFieldConfig.currency,
          }),
        } as NumberFieldConfiguration;
      case FieldDefinitionDataType.choice:
        return choiceFieldConfig;
      default:
        return undefined;
    }
  };

  const showFieldConfigurationByType = () => {
    switch (fieldDataType) {
      case FieldDefinitionDataType.date:
        return (
          <SpaceBetween direction="vertical" size="s">
            <UIField.CustomField
              name="Date Format"
              child={
                <StaticListSelect.Single<string>
                  data={AdvancedListConstants.DISPLAY_DATE_FORMATS}
                  selected={dateFieldConfig.dateFormat}
                  onSelectChange={(format: string) =>
                    setDateFieldConfig({
                      dateFormat: format,
                    })
                  }
                  fromOption={(option: OptionDefinition) => {
                    return option.value;
                  }}
                  toOption={(value: string) => {
                    return { label: value, value: value };
                  }}
                />
              }
            />
            <Box variant="h3">Options</Box>
            <SpaceBetween size="s" direction="vertical">
              <Toggle
                checked={showOnLivePage}
                onChange={({ detail }) => {
                  setShowOnLivePage(detail.checked);
                }}
              >
                Show column on live page
              </Toggle>
            </SpaceBetween>
          </SpaceBetween>
        );
      case FieldDefinitionDataType.number:
        return (
          <SpaceBetween direction="vertical" size="s">
            <UIField.CustomField
              name={`Number Format`}
              description={`Please select desired number format`}
              child={
                <StaticListSelect.Single<NumberFormatUtil.NumberFormat>
                  data={NumberFormatUtil.AVAILABLE_NUMBER_FORMATS}
                  selected={NumberFormatUtil.NUMBER_FORMAT_CONFIGS.find(
                    (i) => i.format === numberFieldConfig.format
                  )}
                  onSelectChange={(
                    selectedFormat: NumberFormatUtil.NumberFormat
                  ) => {
                    setNumberFieldConfig({
                      format: selectedFormat.format,
                    });
                  }}
                  fromOption={(option: OptionDefinition) => {
                    return NumberFormatUtil.NUMBER_FORMAT_CONFIGS.find(
                      (i) => i.format === (option.value as NumberFieldFormat)
                    );
                  }}
                  toOption={(numFormat: NumberFormatUtil.NumberFormat) => {
                    return {
                      label: numFormat.example,
                      value: numFormat.format.toString(),
                    };
                  }}
                />
              }
            />

            <Box variant="h3">Options</Box>
            <SpaceBetween size="xxxs" direction="vertical">
              <Toggle
                checked={showOnLivePage}
                onChange={({ detail }) => {
                  setShowOnLivePage(detail.checked);
                }}
              >
                Show column on live page
              </Toggle>
              <Toggle
                checked={numberFieldConfig.displayAsCurrency}
                onChange={({ detail }) => {
                  setNumberFieldConfig((prev) => ({
                    ...prev,
                    displayAsCurrency: detail.checked,
                  }));
                }}
              >
                Show as Currency
              </Toggle>
            </SpaceBetween>

            {numberFieldConfig.displayAsCurrency && (
              <Box>
                <UIField.CustomField
                  name={"Currency"}
                  description={"Please select currency"}
                  child={
                    <StaticListSelect.Single<string>
                      data={Object.values(CurrencyCode) as string[]}
                      selected={numberFieldConfig.currency}
                      onSelectChange={(selectedCurrency: string) => {
                        setNumberFieldConfig((prev) => ({
                          ...prev,
                          currency: selectedCurrency as CurrencyCode,
                        }));
                      }}
                      fromOption={(option: OptionDefinition) => {
                        return option.value;
                      }}
                      toOption={(currency: string) => {
                        return {
                          label: currency,
                          value: currency,
                        };
                      }}
                    />
                  }
                />
              </Box>
            )}
          </SpaceBetween>
        );
      case FieldDefinitionDataType.choice:
        return <UIField.CustomField
            name="Choice Options"
            child={
              <SpaceBetween size="m">
                <ChoiceOptionInput state={choiceOptionState} />
                <UIField.CustomField
                  name="Choice Column Type"
                  child={<RadioGroup
                    items={[
                      {value: ChoiceFieldType.SINGLE, label: "single"},
                      {value: ChoiceFieldType.MULTIPLE, label: "multiple"},
                    ]}
                    value={choiceFieldConfig.type}
                    onChange={(e) =>
                      setChoiceFieldConfig((prev) => ({
                        ...prev,
                        type: e.detail.value
                      }))
                    }
                  />
                  }
                />
                <Box variant="h3">Options</Box>
                <SpaceBetween size="xxxs" direction="vertical">
                  <Toggle
                    checked={showOnLivePage}
                    onChange={({ detail }) => {
                      setShowOnLivePage(detail.checked);
                    }}
                  >
                    Show column on live page
                  </Toggle>
                  <Toggle
                    checked={allowFreeTextChoiceOption}
                    onChange={({ detail }) => {
                      setAllowFreeTextChoiceOption(detail.checked);
                    }}
                  >
                    Allow free text options
                  </Toggle>
                </SpaceBetween>
              </SpaceBetween>
            }
        />;
      default:
        return <>
          <Box variant="h3">Options</Box>
          <SpaceBetween size="s" direction="vertical">
            <Toggle
              checked={showOnLivePage}
              onChange={({ detail }) => {
                setShowOnLivePage(detail.checked);
              }}
            >
              Show column on live page
            </Toggle>
          </SpaceBetween>
        </>;
    }
  };

  return (
    <>
      <UIField.CustomField
        name={"Column Display Name"}
        description={`How should column to be displayed on live page`}
        child={
          <Input
            value={fieldDisplayName}
            onChange={(e) => setFieldDisplayName(e.detail.value)}
            placeholder={"Enter Column Name"}
          />
        }
      />
      <UIField.CustomField
        name={"Column Data Type"}
        description={`Please select appropriate data type of the column`}
        child={
          <Select
            options={AdvancedListPolarisFactory.Select.getFieldTypeOptions(Object.values(FieldDefinitionDataType))}
            selectedOption={AdvancedListPolarisFactory.Select.getFieldTypeOption(fieldDataType)}
            onChange={(e) => setFieldDataType(e.detail.selectedOption.value as FieldDefinitionDataType)}
            placeholder={"Select Column Data Type"}
          />
        }
      />
      {showFieldConfigurationByType()}
      <br />
      <Button
        disabled={!isValidFieldDefinition()}
        onClick={onAddFieldDefinition}
        variant="primary"
      >
        Add
      </Button>
    </>
  );
};
