import {
    AdvancedList,
    AdvancedListItem,
    IdentityRef,
    LoadAdvancedListItemCommandOutput,
    UpdateAdvancedListItemCommandOutput,
    EntityExtraVersionRef,
    BatchUploadAdvancedListItemAttachmentCommandInput,
    BatchUploadAdvancedListItemAttachmentCommandOutput,
    SandboxFileKeyFileName
} from "@amzn/altar-sds-client";
import { APIOutput, FieldConfigurationsRecord } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    Flashbar,
    FlashbarProps,
    Header,
    Modal,
    SpaceBetween,
    Spinner,
    SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { UpdateAdvancedListItemCommandInputEdit } from "./UpdateAdvancedListItemCommandInputEdit";
import { AttachmentView } from "./attachment-components/AttachmentView";
import { AttachmentUpload } from "./attachment-components/AttachmentUpload";
import { Builder } from "builder-pattern";
import { UIModel } from "../../model/ui-model";

export function UpdateAdvancedListItemModal(props: {
    itemRef: EntityExtraVersionRef;
    advancedList: AdvancedList;
    by: IdentityRef;
    fieldConfigurations?: FieldConfigurationsRecord;
    onUpdated?: (item: AdvancedListItem) => void;
    onCanceled?: () => void;
}) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [updateAdvancedListItemCommandInputEditProps, setUpdateAdvancedListItemCommandInputEditProps] =
        React.useState<Parameters<typeof UpdateAdvancedListItemCommandInputEdit>[0]>();
    const [sandboxFiles, setSandboxFiles] = React.useState<SandboxFileKeyFileName[]>([]);
    const [uploadingSandboxFiles, setUploadingSandboxFiles] = React.useState(false);

    async function init() {
        try {
            setSpinnerProps({});

            const loadItemOutput = await context
                .getAdvancedListAPI()
                .loadItem({
                  entityId: props.itemRef.entityExtraRef.entityRef.entityId,
                  repositoryId: props.itemRef.entityExtraRef.entityRef.repositoryRef.repositoryId,
                  entityExtraId: props.itemRef.entityExtraRef.extraId,
                  by: props.by
                });

            const output = APIOutput.fromRaw<LoadAdvancedListItemCommandOutput>(loadItemOutput.data);
            if (output.isErr()) {
                setFlashbarProps({
                    items: [
                        {
                            type: "error",
                            content: output.err.message
                        }
                    ]
                });
            } else {
                setFlashbarProps({
                    items: []
                });

                setUpdateAdvancedListItemCommandInputEditProps({
                    item: output.data.body,
                    advancedList: props.advancedList,
                    by: props.by
                });
            }
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
        } finally {
            setSpinnerProps(undefined);
        }
    }

    async function updateItem() {
        try {
            setSpinnerProps({});
            let outputAL: AdvancedListItem;

            if (sandboxFiles.length) {
                const uploadFilesOutput = await context
                    .getAdvancedListAPI()
                    .batchUploadItemAttachment(Builder<BatchUploadAdvancedListItemAttachmentCommandInput>()
                        .by(props.by)
                        .sandboxFiles(sandboxFiles)
                        .entityId(props.itemRef.entityExtraRef.entityRef.entityId)
                        .repositoryId(props.itemRef.entityExtraRef.entityRef.repositoryRef.repositoryId!)
                        .entityExtraId(props.itemRef.entityExtraRef.extraId)
                        .build()
                );
                const output = APIOutput.fromRaw<BatchUploadAdvancedListItemAttachmentCommandOutput>(uploadFilesOutput.data);
                if (output.isErr()) {
                    setFlashbarProps({
                        items: [
                            {
                                type: "error",
                                content: output.err.message
                            }
                        ]
                    });
                } else {
                    setFlashbarProps({
                        items: []
                    });
                    outputAL = output.data.body;
                }
            }

            if (updateAdvancedListItemCommandInputEditProps?.value) {
                const updateItemOutput = await context
                    .getAdvancedListAPI()
                    .updateItem(updateAdvancedListItemCommandInputEditProps.value);

                const output = APIOutput.fromRaw<UpdateAdvancedListItemCommandOutput>(updateItemOutput.data);
                if (output.isErr()) {
                    setFlashbarProps({
                        items: [
                            {
                                type: "error",
                                content: output.err.message
                            }
                        ]
                    });
                } else {
                    setFlashbarProps({
                        items: []
                    });
                    outputAL = {
                        ...outputAL,
                        ...output.data.body,
                        attachments: outputAL.attachments
                    };
                }
            }
            props.onUpdated?.(outputAL);
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
        } finally {
            setSpinnerProps(undefined);
        }
    }

    React.useEffect(() => {
        init();
    }, [props.itemRef]);

    return (
        <Modal
            visible={true}
            header={<Header>Update Item</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween size={"s"} direction="horizontal">
                        <Button
                            onClick={(e) => {
                                props.onCanceled?.();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={uploadingSandboxFiles}
                            onClick={(e) => {
                                updateItem();
                            }}
                        >
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {spinnerProps && (
                <Modal visible={true}>
                    <Spinner />
                </Modal>
            )}
            {flashbarProps && <Flashbar {...flashbarProps} />}
            {updateAdvancedListItemCommandInputEditProps && (<>
                <UpdateAdvancedListItemCommandInputEdit
                    {...updateAdvancedListItemCommandInputEditProps}
                    fieldConfigurations={props.fieldConfigurations}
                    onChanged={(value) => {
                        setUpdateAdvancedListItemCommandInputEditProps((prev) => ({
                            ...prev,
                            value: value
                        }));
                    }}
                />
                <hr />
                <AttachmentView
                    item={updateAdvancedListItemCommandInputEditProps?.item}
                    itemRef={props.itemRef}
                    by={props.by}
                />
                <AttachmentUpload
                    itemRef={props.itemRef}
                    by={props.by}
                    onUpdated={(files) => {
                        setSandboxFiles(files);
                    }}
                    setUploadingSandboxFiles={setUploadingSandboxFiles}
                />
            </>)}
        </Modal>
    );
}
