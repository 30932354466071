import * as React from "react";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import { NumberFormatUtil } from "../../../../utils/advanced-list.constant";
import { NumberFieldConfiguration } from "@amzn/ask-legal-domain";

export const NumberFieldView = (props: {
  numberFieldValue: string;
  fieldConfiguration?: NumberFieldConfiguration;
}) => {
  return (
    NumberFormatUtil.numberRegex.test(props.numberFieldValue) && (
      <>
        {props.fieldConfiguration
          ? NumberFormatUtil.formatNumber(
              props.numberFieldValue! as any as number,
              props.fieldConfiguration.format,
              props.fieldConfiguration.currency
            )
          : props.numberFieldValue}
      </>
    )
  );
};
