import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { Button, Header, Table } from "@amzn/awsui-components-react";
import {
  AdvancedListContent,
  FieldConfiguration,
  Identity,
  UpdateAdvancedListPayload,
} from "@amzn/ask-legal-domain";
import { AdvancedList, FieldDefinition } from "@amzn/altar-sds-client";
import { AdvancedListPolarisFactory } from "../../../factory/polaris/advanced-list-polaris-factory";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { AddFieldDefinition } from "./AddFieldDefinition";
import { Builder } from "builder-pattern";
import { UpdateFieldDefinition } from "./UpdateFieldDefinition";

export const AdvancedListContentEdit = (props: {
  state: UIModel.State<AdvancedListContent>;
}) => {
  const context = React.useContext(AppContext);
  const loadAdvancedListRunner = useAPI2(context.getAdvancedListAPI().load);
  const [advancedList, setAdvancedList] = React.useState<AdvancedList>();
  const [userIdentity, setUserIdentity] = React.useState<Identity>(null);
  const [isAddingField, setIsAddingField] = React.useState<boolean>(false);

  const [updateFieldDefinition, setUpdateFieldDefiniton] = React.useState<{
    fieldDef?: FieldDefinition;
    fieldConfiguration?: FieldConfiguration;
  }>({});

  React.useEffect(() => {
    if (userIdentity?.id) {
      loadAdvancedListRunner.invoke({
        entityId: props.state?.value?.entityRef?.entityId,
        repositoryId: props.state?.value?.entityRef?.repositoryRef.repositoryId,
        by: {
          id: userIdentity.id,
          realm: "Amazon",
          type: "Person",
        },
      });
    }
  }, [props.state?.value?.entityRef?.entityId, userIdentity]);

  const fetchIdentity = async () => {
    let currentUser: Identity;
    try {
      currentUser = await context.getIdentity();
    } catch (err) {
      console.warn(err);
    }
    if (!currentUser) {
      console.warn("Error fetching user");
      setUserIdentity(null);
    }
    setUserIdentity(currentUser);
  };

  React.useEffect(() => {
    if (loadAdvancedListRunner.output) {
      setAdvancedList(loadAdvancedListRunner.output.body);
    }
  }, [loadAdvancedListRunner.status]);

  React.useEffect(() => {
    fetchIdentity();
  }, []);

  const onShowOnLivePageChange = (fieldKey: string, checked: boolean) => {
    const data = props.state.value;

    const updatedVisibleFields = new Set(data.visibleFields);

    if (checked) {
      updatedVisibleFields.add(fieldKey);
    } else {
      updatedVisibleFields.delete(fieldKey);
    }

    const updatedData = Builder<AdvancedListContent>(data)
      .visibleFields(Array.from(updatedVisibleFields))
      .build();

    props.state.setValue(updatedData);
  };

  const setFieldRequired = (fieldKey: string, isRequired: boolean) => {
    const fieldDef = [
      ...(props.state?.value?.updateAdvancedListPayload?.addFieldDefinitionsList ?? []),
      ...(advancedList.fieldDefinitions ?? [])
    ].find((def) => def.fieldKey! === fieldKey);

    const updatedData: UpdateAdvancedListPayload = {
      ...props.state?.value?.updateAdvancedListPayload,
      addFieldDefinitionsList: [
        ...(props.state?.value?.updateAdvancedListPayload?.addFieldDefinitionsList?.filter((def) => def.fieldKey! !== fieldKey) ?? []),
        {
          ...fieldDef!,
          required: isRequired
        }
      ]
    };

    props.state.setValue(
      Builder<AdvancedListContent>(props.state.value)
      .updateAdvancedListPayload(updatedData)
      .build()
    );
  };

  const onUpdateColumnDefinition = (
    fieldDef: FieldDefinition,
    fieldConfiguration?: FieldConfiguration
  ) => {
    const updatedData = AdvancedListContent.updateField({
      content: props.state.value,
      fieldDefinition: fieldDef,
      fieldConfiguration: fieldConfiguration,
    });

    props.state.setValue(updatedData);
  };

  const onActionItemClicked = (fieldDef: FieldDefinition, type: string) => {
    switch (type) {
      case "edit":
        setUpdateFieldDefiniton({
          fieldDef: fieldDef,
          fieldConfiguration:
            props.state.value.fieldConfigurations[fieldDef.fieldKey!],
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <hr />
      <Header variant="h3">Advanced List Configuration</Header>

      <br />
      <h4>Manage Columns</h4>
      <Table
        items={[
          ...AdvancedListPolarisFactory.Table.addOrUpdateFieldDefinitions(
            advancedList?.fieldDefinitions ?? [],
            props.state?.value?.updateAdvancedListPayload
              ?.addFieldDefinitionsList ?? []
          ),
        ]}
        columnDefinitions={AdvancedListPolarisFactory.Table.buildManageColumnDefinitions(
          {
            visibileFields: props.state?.value?.visibleFields,
            onShowOnLivePageChange: onShowOnLivePageChange,
            onRequiredStatusChange: setFieldRequired,
            onActionItemClicked: onActionItemClicked,
          }
        )}
        loading={loadAdvancedListRunner.status === "Running"}
      />

      <br />
      {!isAddingField && (
        <Button
          onClick={() => {
            setIsAddingField(true);
            setUpdateFieldDefiniton(undefined);
          }}
        >
          Add Column
        </Button>
      )}
      {isAddingField && (
        <>
          <AddFieldDefinition
            state={props.state}
            onChange={() => setIsAddingField(false)}
          />
          <Button onClick={() => setIsAddingField(false)}>Cancel</Button>
        </>
      )}
      <hr />
      {updateFieldDefinition && updateFieldDefinition?.fieldDef && (
        <>
          <UpdateFieldDefinition
            state={props.state}
            fieldDef={updateFieldDefinition.fieldDef}
            fieldConfiguration={updateFieldDefinition.fieldConfiguration}
            onUpdated={(value) => {
              onUpdateColumnDefinition(
                value.fieldDef,
                value.fieldConfiguration
              );
              setUpdateFieldDefiniton(undefined);
            }}
            onCancel={() => {
              setUpdateFieldDefiniton(undefined);
            }}
          />
        </>
      )}
    </>
  );
};
