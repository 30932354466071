import * as React from "react";
import { Box, SpaceBetween } from "@amzn/awsui-components-react";
import { DateFieldConfiguration } from "@amzn/ask-legal-domain";
import { DateTimeFormatter } from "../../../../utils/date-time-utils";
import { AdvancedListConstants } from "../../../../utils/advanced-list.constant";

export const DateFieldView = (props: {
  dateFieldValue: string;
  fieldConfiguration?: DateFieldConfiguration;
}) => {
  return (
    props.dateFieldValue.trim().length > 0 && (
      <>
        {
        props.fieldConfiguration
          ? DateTimeFormatter.applyDateFormattingOnYYYYMMDD(
              props.dateFieldValue as string,
              props.fieldConfiguration.dateFormat
            )
          : DateTimeFormatter.applyDateFormattingOnYYYYMMDD(
              props.dateFieldValue as string,
              AdvancedListConstants.DEFAULT_DATE_FORMAT
            )
        }
       </>
    )
  );
};
