import * as React from "react";
import {
  Button,
  FormFieldProps,
  Input,
  InputProps,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { FieldDefinition } from "@amzn/altar-sds-client";
import { UIField } from "../../../../common/UIField";

export const PersonFieldEdit = (props: {
  value: {
    fieldDef: FieldDefinition;
  };
  onUpdated?: (value: { fieldDef: FieldDefinition }) => void;
  onCancel?: () => void;
}) => {
  const [fieldColumnDisplayName, setFieldColumnDisplayName] =
    React.useState<FormFieldProps>({});
  const [columnDisplayName, setColumnDisplayName] = React.useState<InputProps>({
    value: props.value.fieldDef.displayName,
  });

  const [valid, setValid] = React.useState<boolean>(false);

  function validateColumnDisplayName() {
    if (columnDisplayName.value?.length > 0) {
      setFieldColumnDisplayName({
        errorText: "",
      });
      setValid(true);
    } else {
      setFieldColumnDisplayName({
        errorText: "Column Display Name is required",
      });
      setValid(false);
    }
  }

  React.useEffect(() => {
    validateColumnDisplayName();
  }, [columnDisplayName.value]);

  React.useEffect(() => {
    if (!props.value.fieldDef) return;
    setColumnDisplayName({ value: props.value.fieldDef.displayName });
  }, [props.value]);

  const onPersonFieldUpdate = () => {
    if (!valid) {
      console.log(`not a valid data`);
      return;
    }
    props.onUpdated?.({
      fieldDef: {
        ...props.value.fieldDef,
        displayName: columnDisplayName.value!,
      },
    });
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      <SpaceBetween direction="vertical" size="xxs">
        <UIField.CustomField
          name={"Column Display Name"}
          description={"How should column to be displayed on live page"}
          errorText={fieldColumnDisplayName.errorText}
          child={
            <Input
              value={columnDisplayName.value}
              onChange={(e) => setColumnDisplayName({ value: e.detail.value })}
              placeholder={"Enter Column Name"}
            />
          }
        />
      </SpaceBetween>

      <SpaceBetween direction="horizontal" size="xxs">
        <Button variant="primary" onClick={onPersonFieldUpdate}>
          Update
        </Button>

        <Button onClick={props.onCancel}>Cancel</Button>
      </SpaceBetween>
    </SpaceBetween>
  );
};
