import {
  CreateAdvancedListItemCommandInput,
  CreateAdvancedListItemCommandOutput,
  LoadAdvancedListCommandInput,
  LoadAdvancedListCommandOutput,
  UpdateAdvancedListItemCommandInput,
  UpdateAdvancedListItemCommandOutput,
  SearchAdvancedListItemsCommandInput,
  SearchAdvancedListItemsCommandOutput,
  LoadAdvancedListItemCommandInput,
  LoadAdvancedListItemCommandOutput,
  DeleteAdvancedListItemCommandInput,
  DeleteAdvancedListItemCommandOutput,
  BatchUploadAdvancedListItemAttachmentCommandInput,
  BatchUploadAdvancedListItemAttachmentCommandOutput,
  DownloadAdvancedListItemAttachmentCommandInput,
  DownloadAdvancedListItemAttachmentCommandOutput,
  GenInstanceSandboxFileGetUrlCommandInput,
  GenInstanceSandboxFileGetUrlCommandOutput,
  GenInstanceSandboxFilePutUrlCommandInput,
  GenInstanceSandboxFilePutUrlCommandOutput
} from "@amzn/altar-sds-client";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface AdvancedListAPI {
  load(input: LoadAdvancedListCommandInput): Promise<APIResponse<LoadAdvancedListCommandOutput>>;
  createItem(input: CreateAdvancedListItemCommandInput): Promise<APIResponse<CreateAdvancedListItemCommandOutput>>;
  loadItem(input: LoadAdvancedListItemCommandInput): Promise<APIResponse<LoadAdvancedListItemCommandOutput>>;
  deleteItem(input: DeleteAdvancedListItemCommandInput): Promise<APIResponse<DeleteAdvancedListItemCommandOutput>>;
  updateItem(input: UpdateAdvancedListItemCommandInput): Promise<APIResponse<UpdateAdvancedListItemCommandOutput>>;
  searchItems(input: SearchAdvancedListItemsCommandInput): Promise<APIResponse<SearchAdvancedListItemsCommandOutput>>;
  batchUploadItemAttachment(input: BatchUploadAdvancedListItemAttachmentCommandInput): Promise<APIResponse<BatchUploadAdvancedListItemAttachmentCommandOutput>>;
  downloadItemAttachment(input: DownloadAdvancedListItemAttachmentCommandInput): Promise<APIResponse<DownloadAdvancedListItemAttachmentCommandOutput>>;
  generateSandboxPutUrl(input: GenInstanceSandboxFileGetUrlCommandInput): Promise<APIResponse<GenInstanceSandboxFileGetUrlCommandOutput>>;
  generateSandboxPutUrl(input: GenInstanceSandboxFilePutUrlCommandInput): Promise<APIResponse<GenInstanceSandboxFilePutUrlCommandOutput>>;
}

export class AdvancedListAPIImpl implements AdvancedListAPI {
  constructor(private context: AppContextValue) {}

  async load(input: LoadAdvancedListCommandInput): Promise<APIResponse<LoadAdvancedListCommandOutput>> {
    const response: APIResponse<LoadAdvancedListCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/load",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async createItem(
    input: CreateAdvancedListItemCommandInput
  ): Promise<APIResponse<CreateAdvancedListItemCommandOutput>> {
    const response: APIResponse<CreateAdvancedListItemCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/createItem",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async loadItem(input: LoadAdvancedListItemCommandInput): Promise<APIResponse<LoadAdvancedListItemCommandOutput>> {
    const response: APIResponse<LoadAdvancedListItemCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/loadItem",
      toDefaultRequestPayload(input)
    );
    return response;
  }
  async deleteItem(input: DeleteAdvancedListItemCommandInput): Promise<APIResponse<DeleteAdvancedListItemCommandOutput>> {
    const response: APIResponse<DeleteAdvancedListItemCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/deleteItem",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async updateItem(
    input: UpdateAdvancedListItemCommandInput
  ): Promise<APIResponse<UpdateAdvancedListItemCommandOutput>> {
    const response: APIResponse<UpdateAdvancedListItemCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/updateItem",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async searchItems(
    input: SearchAdvancedListItemsCommandInput
  ): Promise<APIResponse<SearchAdvancedListItemsCommandOutput>> {
    const response: APIResponse<SearchAdvancedListItemsCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/searchItems",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async batchUploadItemAttachment(
    input: BatchUploadAdvancedListItemAttachmentCommandInput
  ): Promise<APIResponse<BatchUploadAdvancedListItemAttachmentCommandOutput>> {
    const response: APIResponse<BatchUploadAdvancedListItemAttachmentCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/batchUploadAdvancedListItemAttachment",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async downloadItemAttachment(
    input: DownloadAdvancedListItemAttachmentCommandInput
  ): Promise<APIResponse<DownloadAdvancedListItemAttachmentCommandOutput>> {
    const response: APIResponse<DownloadAdvancedListItemAttachmentCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/downloadAdvancedListItemAttachment",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async generateSandboxGetUrl(
    input: GenInstanceSandboxFileGetUrlCommandInput
  ): Promise<APIResponse<GenInstanceSandboxFileGetUrlCommandOutput>> {
    const response: APIResponse<GenInstanceSandboxFileGetUrlCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/generateSandboxGetUrl",
      toDefaultRequestPayload(input)
    );
    return response;
  }


  async generateSandboxPutUrl(
    input: GenInstanceSandboxFilePutUrlCommandInput
  ): Promise<APIResponse<GenInstanceSandboxFilePutUrlCommandOutput>> {
    const response: APIResponse<GenInstanceSandboxFilePutUrlCommandOutput> = await RestAPI.post(
      "custom-domain",
      "/advanced-list/generateSandboxPutUrl",
      toDefaultRequestPayload(input)
    );
    return response;
  }
}
