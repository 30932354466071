import * as React from "react";
import {
  Box,
  Button,
  FormFieldProps,
  Input,
  InputProps,
  RadioGroup,
  SpaceBetween,
  Toggle,
  TokenGroup,
} from "@amzn/awsui-components-react";
import { FieldDefinition, ChoiceOption } from "@amzn/altar-sds-client";
import { UIField } from "../../../../common/UIField";
import { ChoiceFieldConfiguration, ChoiceFieldType } from "@amzn/ask-legal-domain";
import { UIModel } from "../../../../../model/ui-model";

export const ChoiceFieldEdit = (props: {
  value: {
    fieldDef: FieldDefinition;
    fieldConfiguration?: ChoiceFieldConfiguration;
  };
  onUpdated?: (value: {
    fieldDef: FieldDefinition;
    fieldConfiguration?: ChoiceFieldConfiguration;
  }) => void;
  onCancel?: () => void;
}) => {
  const [fieldColumnDisplayName, setFieldColumnDisplayName] =
    React.useState<FormFieldProps>({});
  const [columnDisplayName, setColumnDisplayName] = React.useState<InputProps>({
    value: props.value.fieldDef.displayName,
  });

  const [allowFreeTextOption, setAllowFreeTextOption] = React.useState<boolean>(props.value.fieldDef.allowFreeTextOption ?? false);

  const [fieldChoiceType, setFieldChoiceType] = React.useState<FormFieldProps>({});

  const [choiceFieldConfig, setChoiceFieldConfig] =
    React.useState<ChoiceFieldConfiguration>({
      type: ChoiceFieldType.SINGLE,
    });
  const choiceOptionState = UIModel.State.use<ChoiceOption[]>({initialValue: []});

  const [valid, setValid] = React.useState<boolean>(false);

  function validateColumnDisplayName() {
    if (columnDisplayName.value?.length > 0) {
      setFieldColumnDisplayName({
        errorText: "",
      });
      setValid(true);
    } else {
      setFieldColumnDisplayName({
        errorText: "Column Display Name is required",
      });
      setValid(false);
    }
  }

  function validateChoiceType() {
    if (choiceFieldConfig?.type?.trim().length > 0) {
      setFieldChoiceType({
        errorText: "",
      });
      setValid(true);
    } else {
      setFieldChoiceType({
        errorText: "Choice type selection is required",
      });
      setValid(false);
    }
  }

  React.useEffect(() => {
    validateColumnDisplayName();
  }, [columnDisplayName.value]);

  React.useEffect(() => {
    validateChoiceType();
  }, [choiceFieldConfig.type]);

  React.useEffect(() => {
    if (!props.value.fieldDef) return;
    setColumnDisplayName({ value: props.value.fieldDef.displayName });
    setAllowFreeTextOption(props.value.fieldDef.allowFreeTextOption ?? false);

    setChoiceFieldConfig({
      type: props.value.fieldConfiguration?.type ?? ChoiceFieldType.SINGLE,
    });

    choiceOptionState.setValue(props.value.fieldDef.choiceOptions ?? []);
  }, [props.value]);

  const onChoiceFieldUpdate = () => {
    if (!valid) {
      console.log(`not a valid data`);
      return;
    }
    props.onUpdated?.({
      fieldDef: {
        ...props.value.fieldDef,
        displayName: columnDisplayName.value!,
        allowFreeTextOption: allowFreeTextOption
      },
      fieldConfiguration: {
        ...props.value.fieldConfiguration,
        type: choiceFieldConfig.type,
      },
    });
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      <SpaceBetween direction="vertical" size="xxs">
        <UIField.CustomField
          name={"Column Display Name"}
          description={"How should column to be displayed on live page"}
          errorText={fieldColumnDisplayName.errorText}
          child={
            <Input
              value={columnDisplayName.value}
              onChange={(e) => setColumnDisplayName({ value: e.detail.value })}
              placeholder={"Enter Column Name"}
            />
          }
        />

        Choice Options:
        <TokenGroup
          items={[
            ...choiceOptionState.value.map((choice) => ({
              label: choice.displayValue,
              value: choice.key
            }))
          ]}
        />
        <UIField.CustomField
          name="Choice Column Type"
          errorText={fieldChoiceType.errorText}
          child={<RadioGroup
            items={[
              {value: ChoiceFieldType.SINGLE, label: "single"},
              {value: ChoiceFieldType.MULTIPLE, label: "multiple"},
            ]}
            value={choiceFieldConfig.type}
            onChange={(e) =>
              setChoiceFieldConfig({
                type: e.detail.value
              })
            }
          />
          }
        />

        <Box variant="h3">Options</Box>
        <SpaceBetween size="xxxs" direction="vertical">
          <Toggle
            checked={allowFreeTextOption}
            onChange={({ detail }) => {
              setAllowFreeTextOption(detail.checked);
            }}
          >
            Allow free text options
          </Toggle>
        </SpaceBetween>
      </SpaceBetween>

      <SpaceBetween direction="horizontal" size="xxs">
        <Button variant="primary" onClick={onChoiceFieldUpdate}>
          Update
        </Button>

        <Button onClick={props.onCancel}>Cancel</Button>
      </SpaceBetween>
    </SpaceBetween>
  );
};
