import * as React from "react";
import {
  Button,
  FormFieldProps,
  Input,
  InputProps,
  SelectProps,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { DateFieldConfiguration } from "@amzn/ask-legal-domain";
import { FieldDefinition } from "@amzn/altar-sds-client";
import { StaticListSelect } from "../../../../common/StaticListSelect";
import { AdvancedListConstants } from "../../../../../utils/advanced-list.constant";
import { UIField } from "../../../../common/UIField";

export const DateFieldEdit = (props: {
  value: {
    fieldDef: FieldDefinition;
    fieldConfiguration?: DateFieldConfiguration;
  };
  onUpdated?: (value: {
    fieldDef: FieldDefinition;
    fieldConfiguration?: DateFieldConfiguration;
  }) => void;
  onCancel?: () => void;
}) => {
  const [fieldColumnDisplayName, setFieldColumnDisplayName] =
    React.useState<FormFieldProps>({});
  const [columnDisplayName, setColumnDisplayName] = React.useState<InputProps>({
    value: props.value.fieldDef.displayName,
  });

  const [fieldDateFormat, setFieldDateFormat] = React.useState<FormFieldProps>(
    {}
  );

  const [dateFieldConfig, setDateFieldConfig] =
    React.useState<DateFieldConfiguration>({
      dateFormat: AdvancedListConstants.DEFAULT_DATE_FORMAT,
    });
  const [valid, setValid] = React.useState<boolean>(false);

  function validateColumnDisplayName() {
    if (columnDisplayName.value?.length > 0) {
      setFieldColumnDisplayName({
        errorText: "",
      });
      setValid(true);
    } else {
      setFieldColumnDisplayName({
        errorText: "Column Display Name is required",
      });
      setValid(false);
    }
  }

  function validateDateFormat() {
    if (dateFieldConfig?.dateFormat?.trim().length > 0) {
      setFieldDateFormat({
        errorText: "",
      });
      setValid(true);
    } else {
      setFieldDateFormat({
        errorText: "Date format selection is required",
      });
      setValid(false);
    }
  }

  React.useEffect(() => {
    validateColumnDisplayName();
  }, [columnDisplayName.value]);

  React.useEffect(() => {
    validateDateFormat();
  }, [dateFieldConfig.dateFormat]);

  React.useEffect(() => {
    if (!props.value.fieldDef) return;
    setColumnDisplayName({ value: props.value.fieldDef.displayName });

    setDateFieldConfig({
      dateFormat:
        props.value.fieldConfiguration?.dateFormat ??
        AdvancedListConstants.DEFAULT_DATE_FORMAT,
    });
  }, [props.value]);

  const onDateFieldUpdate = () => {
    if (!valid) {
      console.log(`not a valid data`);
      return;
    }
    props.onUpdated?.({
      fieldDef: {
        ...props.value.fieldDef,
        displayName: columnDisplayName.value!,
      },
      fieldConfiguration: {
        ...props.value.fieldConfiguration,
        dateFormat: dateFieldConfig.dateFormat,
      },
    });
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      <SpaceBetween direction="vertical" size="xxs">
        <UIField.CustomField
          name={"Column Display Name"}
          description={"How should column to be displayed on live page"}
          errorText={fieldColumnDisplayName.errorText}
          child={
            <Input
              value={columnDisplayName.value}
              onChange={(e) => setColumnDisplayName({ value: e.detail.value })}
              placeholder={"Enter Column Name"}
            />
          }
        />

        <UIField.CustomField
          name="Date Format"
          errorText={fieldDateFormat.errorText}
          child={
            <StaticListSelect.Single<string>
              data={AdvancedListConstants.DISPLAY_DATE_FORMATS}
              selected={dateFieldConfig.dateFormat}
              onSelectChange={(format: string) =>
                setDateFieldConfig({
                  dateFormat: format,
                })
              }
              fromOption={(option: SelectProps.Option) => {
                return option.value;
              }}
              toOption={(value: string) => {
                return { label: value, value: value };
              }}
            />
          }
        />
      </SpaceBetween>

      <SpaceBetween direction="horizontal" size="xxs">
        <Button variant="primary" onClick={onDateFieldUpdate}>
          Update
        </Button>

        <Button onClick={props.onCancel}>Cancel</Button>
      </SpaceBetween>
    </SpaceBetween>
  );
};
