import { CurrencyCode, NumberFieldFormat } from "@amzn/ask-legal-domain";

export namespace AdvancedListConstants {
    export const CHARACTER_LIMIT_OF_FREE_TEXT_OPTION = 200;

    export const DISPLAY_DATE_FORMATS: string[] = [
        "YYYY-MM-DD",
        "DD-MM-YYYY",
        "MM-DD-YYYY",
        "DD/MM/YYYY",
        "MM/DD/YYYY",
        "Mon D, YYYY",
        "Month D, YYYY"
    ];

    export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

    export enum BOOLEAN_YES_NO_DISPLAY_VALUES {
        Yes = "Yes",
        No = "No"
    }
}

export namespace NumberFormatUtil {
    export const numberRegex = /^-?\d+(\.\d+)?$/;
    export interface NumberFormat {
        format: NumberFieldFormat;
        example: string;
        locale: string;
        config: Intl.NumberFormatOptions;
    }

    export const NUMBER_FORMAT_CONFIGS: NumberFormat[] = [
        {
            format: NumberFieldFormat.COMMAS_TWO_DECIMAL,
            example: "100,000.00",
            locale: "en-US",
            config: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        },
        {
            format: NumberFieldFormat.COMMAS_NO_DECIMAL,
            example: "100000",
            config: { minimumFractionDigits: 0, maximumFractionDigits: 0 },
            locale: "en-US",
        },
        {
            format: NumberFieldFormat.COMMAS_THREE_DECIMAL,
            example: "100000.000",
            config: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
            locale: "en-US",
        },
        {
            format: NumberFieldFormat.INDIAN_NUMBER_TWO_DECIMAL,
            example: "1,00,000.00",
            config: { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true },
            locale: "en-IN",
        },
        {
            format: NumberFieldFormat.INDIAN_NUMBER_NO_DECIMAL,
            example: "1,00,000",
            config: { minimumFractionDigits: 0, maximumFractionDigits: 0, useGrouping: true },
            locale: "en-IN",
        }
    ];

    export const DEFAULT_NUMBER_FORMAT: NumberFormat = NUMBER_FORMAT_CONFIGS[0];

    export const AVAILABLE_NUMBER_FORMATS: NumberFormat[] = [
        ...NUMBER_FORMAT_CONFIGS
    ];

    export function formatNumber(numberValue: number, format: NumberFieldFormat, currency?: CurrencyCode): string {
        const formatter = getNumberFormatter(format, currency);
        if (formatter) {
          return formatter.format(numberValue);
        }
        return numberValue.toString();
    }

    function getNumberFormatter(format: NumberFieldFormat, currency?: CurrencyCode): Intl.NumberFormat | undefined {
        const numberFormat = NUMBER_FORMAT_CONFIGS.find((f) => f.format === format);
        if (numberFormat) {
            return new Intl.NumberFormat(numberFormat.locale, {
                ...numberFormat.config,
                ...currency && {
                    style: "currency",
                    currency: currency
                }
            });
        }
        return undefined;
    }
}