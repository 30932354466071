import * as React from "react";
import { ChoiceFieldConfiguration, ChoiceFieldType } from "@amzn/ask-legal-domain";
import { ChoiceOption } from "@amzn/altar-sds-client";
import { SpaceBetween } from "@amzn/awsui-components-react";

export const ChoiceFieldView = (props: {
    value: ChoiceOption[]
    fieldConfiguration?: ChoiceFieldConfiguration;

}) => {
    if (props.fieldConfiguration?.type === ChoiceFieldType.MULTIPLE) {
        if (!props.value?.length) return <>-</>;
        else if (props.value?.length === 1) return <>{props.value[0].displayValue?.trim() || props.value[0].displayValue}</>;
        else return <SpaceBetween direction="vertical" size="xxs">{props.value?.map(x => <>{x.displayValue?.trim() || x.displayValue}</>)}</SpaceBetween>;
    }
    return (
        <div>{props.value?.[0]?.displayValue}</div>
    );
};