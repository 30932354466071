import {
    AdvancedListItem,
    IdentityRef,
    DownloadAdvancedListItemAttachmentCommandOutput,
    EntityExtraVersionRef
} from "@amzn/altar-sds-client";
import { APIOutput } from "@amzn/ask-legal-domain";
import {
    Flashbar,
    FlashbarProps,
    Link,
    SpaceBetween,
    Spinner,
    SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";

interface SpinnerPropsEx extends SpinnerProps {
    key: string;
}

export function AttachmentView(props: {
    item: AdvancedListItem
    itemRef: EntityExtraVersionRef;
    by: IdentityRef;
}) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerPropsEx>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();

    async function downloadAttachment(key: string) {
        try {
            setSpinnerProps({key: key});
            const input = {
                repositoryId: props.itemRef.entityExtraRef.entityRef.repositoryRef.repositoryId,
                entityId: props.itemRef.entityExtraRef.entityRef.entityId,
                entityExtraId: props.itemRef.entityExtraRef.extraId,
                key: key,
                by: props.by
            };
            const loadAttachmentOutput = await context
                .getAdvancedListAPI()
                .downloadItemAttachment(input);
            const output = APIOutput.fromRaw<DownloadAdvancedListItemAttachmentCommandOutput>(loadAttachmentOutput.data);

            if (output.isErr()) {
                setFlashbarProps({
                    items: [
                        {
                            type: "error",
                            content: output.err.message
                        }
                    ]
                });
            } else {
                setFlashbarProps({items: []});
                window.open(output.data.downloadUrl, "_blank");
            }
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
        } finally {
            setSpinnerProps(undefined);
        }
    }

    return (<>
        {flashbarProps && <Flashbar {...flashbarProps} />}
        <h4>Attachments</h4>
        <ul>
            {props.item.attachments?.map(v => <>
                <li>
                    <SpaceBetween direction="horizontal" size="m">
                        <Link
                            onFollow={() => downloadAttachment(v.key)}
                        >
                            {v.fileName}
                        </Link>
                        {spinnerProps && spinnerProps.key === v.key && (
                            <Spinner />
                        )}
                    </SpaceBetween>
                </li>
            </>)}
        </ul>
    </>);
}
