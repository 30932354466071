import {
    Box,
    ColumnLayout,
    Flashbar,
    FlashbarProps,
    Modal,
    Spinner,
    SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { APIOutput } from "@amzn/ask-legal-domain";
import { RichTextView } from "../common/RichTextEditor/RichTextView";

export function PageSummary(props: { pageId: string; pageVersion?: number }) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [summary, setSummary] = React.useState<string>();

    async function init() {
        try {
            setSpinnerProps({});

            const getSummaryOutput = await context.getPageAPI().getSummary({
                pageId: props.pageId,
                version: props.pageVersion
            });

            const output = APIOutput.fromRaw<string>(getSummaryOutput.data);
            if (output.isErr()) {
                setFlashbarProps({
                    items: [
                        {
                            type: "error",
                            content: output.err.message
                        }
                    ]
                });
            } else {
                setFlashbarProps({
                    items: []
                });
                setSummary(output.data);
            }
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
            setSummary(undefined);
        } finally {
            setSpinnerProps(undefined);
        }
    }

    React.useEffect(() => {
        init();
    }, []);

    return (
        <ColumnLayout columns={1}>
            {spinnerProps && (
                <Modal visible={true}>
                    <Spinner />
                </Modal>
            )}
            {flashbarProps && <Flashbar {...flashbarProps} />}
			<RichTextView content={summary}/>
        </ColumnLayout>
    );
}
