import { Page, IPage, EntityRef, ServerSidePaginatedLoadingInput, ServerSidePaginatedLoadingOutput } from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface PageAPI {
    create(input: IPage.CreatePageInput): Promise<APIResponse<Page.Data>>;
    updateMetadata(input: IPage.UpdatePageMetadataInput): Promise<APIResponse<Page.Data>>;
    checkAdminAccess(id: string): Promise<APIResponse<Page.Data>>;
    load(id: string): Promise<APIResponse<Page.Data>>;
    load2(id: string): Promise<APIResponse<IPage.LoadPageOutput>>;
    loadAll(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>>>;
    loadAllByInstance(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>>>;
    searchByName(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>>>;
    loadVersion(entityRef: EntityRef): Promise<APIResponse<Page.Data>>;
    loadAllVersions(entityRef: EntityRef): Promise<APIResponse<Page.Data[]>>;
    deprecate(input: IPage.DeprecatePageInput): Promise<APIResponse<Page.Data>>;
    activate(id: string): Promise<APIResponse<Page.Data>>;
    delete(id: string): Promise<APIResponse<Page.Data>>;
    checkFreshness(id: string): Promise<APIResponse<boolean>>;
    deepCopy(input: IPage.DeepCopyPageInput): Promise<APIResponse<IPage.DeepCopyPageOutput>>;
    getSummary(input: IPage.GetPageSummaryInput): Promise<APIResponse<string>>;
    getChangeSummary(input: IPage.GetPageChangeSummaryInput): Promise<APIResponse<string>>;
}

export class PageAPIImpl implements PageAPI {
    constructor(private context: AppContextValue) { }

    async getSummary(input: IPage.GetPageSummaryInput): Promise<APIResponse<string>> {
        const response: APIResponse<string> = await RestAPI.post(
            "custom-domain",
            "/page/getPageSummary",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async getChangeSummary(input: IPage.GetPageChangeSummaryInput): Promise<APIResponse<string>> {
        const response: APIResponse<string> = await RestAPI.post(
            "custom-domain",
            "/page/getPageChangeSummary",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async deepCopy(input: IPage.DeepCopyPageInput): Promise<APIResponse<IPage.DeepCopyPageOutput>> {
        const response: APIResponse<IPage.DeepCopyPageOutput> = await RestAPI.post(
            "custom-domain",
            "/page/deep-copy",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async checkFreshness(id: string): Promise<APIResponse<boolean>> {
        const response: APIResponse<boolean> = await RestAPI.post(
            "custom-domain",
            "/page/check-freshness",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async delete(id: string): Promise<APIResponse<Page.Data>> {
        const response: APIResponse<Page.Data> = await RestAPI.post(
            "custom-domain",
            "/page/delete",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async deprecate(input: IPage.DeprecatePageInput): Promise<APIResponse<Page.Data>> {
        const response: APIResponse<Page.Data> = await RestAPI.post(
            "custom-domain",
            "/page/deprecate",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async activate(id: string): Promise<APIResponse<Page.Data>> {
        const response: APIResponse<Page.Data> = await RestAPI.post(
            "custom-domain",
            "/page/activate",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async create(
        input: IPage.CreatePageInput
    ): Promise<APIResponse<Page.Data>> {
        const response: APIResponse<Page.Data> = await RestAPI.post(
            "custom-domain",
            "/page/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async updateMetadata(input: IPage.UpdatePageMetadataInput): Promise<APIResponse<Page.Data>> {
        const response: APIResponse<Page.Data> = await RestAPI.post(
            "custom-domain",
            "/page/update-metadata",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async checkAdminAccess(
        input: string
    ): Promise<APIResponse<Page.Data>> {
        const response: APIResponse<Page.Data> = await RestAPI.post(
            "custom-domain",
            "/page/check-admin-access",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load(
        input: string
    ): Promise<APIResponse<Page.Data>> {
        const response: APIResponse<Page.Data> = await RestAPI.post(
            "custom-domain",
            "/page/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load2(
        input: string
    ): Promise<APIResponse<IPage.LoadPageOutput>> {
        const response: APIResponse<IPage.LoadPageOutput> = await RestAPI.post(
            "custom-domain",
            "/page/load2",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadAll(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>> = await RestAPI.post(
            "custom-domain",
            "/page/load-all",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadAllByInstance(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>> = await RestAPI.post(
            "custom-domain",
            "/page/load-all-for-instance",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async searchByName(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>> = await RestAPI.post(
            "custom-domain",
            "/page/searchByName",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadVersion(entityRef: EntityRef): Promise<APIResponse<Page.Data>> {
        const response: APIResponse<Page.Data> = await RestAPI.post(
            "custom-domain",
            "/page/load-version",
            toDefaultRequestPayload(entityRef)
        );
        return response;
    }

    async loadAllVersions(entityRef: EntityRef): Promise<APIResponse<Page.Data[]>> {
        const response: APIResponse<Page.Data[]> = await RestAPI.post(
            "custom-domain",
            "/page/load-all-versions",
            toDefaultRequestPayload(entityRef)
        );
        return response;
    }
}
