import { IdentityRef } from "@amzn/altar-sds-client";
import { APIOutput, EmployeeIdentity } from "@amzn/ask-legal-domain";
import { IconProps } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { AppContext } from "../../../setup/context";
import { IdentityLink } from "../../common/IdentityLink";
import React = require("react");

export function PersonViewComp(props: { identityRef: IdentityRef }) {
    const context = React.useContext(AppContext);
    const [name, setName] = useState<string>();
    const [warningIcon, setWarningIcon] = useState<IconProps>();

    async function init() {
        const searchResponse = await context.getUserSearchAPI().searchUserByLogin({
            userName: props.identityRef.id,
            limit: 1
        });
        const output = APIOutput.fromRaw<EmployeeIdentity[]>(searchResponse.data);
        if (output.isOk() && output.data.length > 0) {
            setName(output.data[0].name);
            setWarningIcon(undefined);
        } else {
            setWarningIcon({});
        }
    }

    useEffect(() => {
        init();
    }, [props.identityRef]);

    return <IdentityLink id={props.identityRef.id} name={name} />;
}
