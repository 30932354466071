import * as React from "react";
import {
    Alert,
    Flashbar,
    FlashbarProps,
    Header,
    SpaceBetween,
    Spinner,
    Tabs
} from "@amzn/awsui-components-react";
import { PageOverviewTab } from "../../components/page/PageOverview";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { UIModel } from "../../model/ui-model";
import { EntityFactory, Page } from "@amzn/ask-legal-domain";
import { PageDraftDetail } from "../../components/page-draft/PageDraftDetail";
import { EntityPermissionDetail } from "../../components/common/EntityPermissionComp";
import { PageLive } from "./PageLive";
import { PageVersionHistory } from "../../components/page/PageVersionHistoryTab";
import { IdentityLink } from "../../components/common/IdentityLink";
import { PermissionDenied } from "../redirects/PermissionDenied";
import { PageNotFound } from "../redirects/PageNotFound";
import { useHistory } from "react-router";
import { getQueryParameterMap } from "../../utils/common-utils";

export const PagePortal = (props: {
    id: string;
    activeTabId?: string;
}) => {
    const context = React.useContext(AppContext);
    const history = useHistory();
    const loadPageRunner = useAPI(
        context.getPageAPI().checkAdminAccess
    );
    const pageDataState = UIModel.State.use<Page.Data>({});
    const DEFAULT_TAB_ID = "overview";
    const [activeTabId, setActiveTabId] = React.useState(props.activeTabId ? props.activeTabId : DEFAULT_TAB_ID);
    const [flashbarItems, setFlashBarItems] = React.useState<FlashbarProps.MessageDefinition[]>([]);
    const [isEditing, setIsEditing] = React.useState<boolean>(false);

    const setTabURL = (tabId: string): string => {
        const currenthash = window.location.hash.slice(1).split("?")[0];
        // Clear any other params currently since they have no meaning across tabs
        return `${currenthash}?tab=${tabId}`;
    };

    React.useEffect(() => {
        // Hook to set default entry tabId url
        const queryParamMap = getQueryParameterMap();
        if (!queryParamMap.get("tab")) {
            window.history.replaceState(
                null,
                "",
                `${window.location.hash}?tab=${DEFAULT_TAB_ID}`
            );
        }
    }, []);

    React.useEffect(() => {
        loadPageRunner.submitRun(
            props.id
        );
    }, [props.id]);

    React.useEffect(() => {
        // Hook to handle user history actions on tabs
        history.listen((location, action) => {
            if (action === "POP") {
                const urlTab = location.search.slice(1).split("&").map(v => v.split("=")).filter(x => x[0] === "tab")[0];
                if (!urlTab) {
                    setActiveTabId(DEFAULT_TAB_ID);
                    return;
                }
                setActiveTabId(urlTab[1]);
            }
        });
    }, [history]);

    React.useEffect(() => {
        if (loadPageRunner.status === "Succeeded") {
            pageDataState.setValue(loadPageRunner.data.output);
        }
    }, [loadPageRunner.status]);

    return (
        <React.Fragment>
            <SpaceBetween size="s" direction="vertical">
                {loadPageRunner.status === "Succeeded" && !!pageDataState.value && pageDataState.value.deprecated &&
                    <Alert
                        type="info"
                        header="Deprecated">
                        This page was last updated on {
                            pageDataState.value.lastModifiedDate
                        } by&nbsp;
                        <IdentityLink
                            id={pageDataState.value.lastModifiedBy.id}
                            name={pageDataState.value.lastModifiedBy.name}
                        /> with message "{pageDataState.value.lastModifiedCommit}"
                    </Alert>
                }
                {loadPageRunner.status === "Succeeded" && !!pageDataState.value && !pageDataState.value.deprecated &&
                    <Flashbar items={flashbarItems}/>
                }
                <Header id="pageHeader" variant="h1">Page: {props.id.split("-")[2]}</Header>
                <React.Fragment>
                    {loadPageRunner.status === "Running" && <Spinner />}
                    {loadPageRunner.status === "Error" && (
                        loadPageRunner.data.err.code === 403 ?
                            <PermissionDenied entityId={props.id} accessType="PageAdminPortal"/> : <PageNotFound/>
                    )}
                    {loadPageRunner.status === "Succeeded" && !pageDataState.value && <Spinner />}
                    {loadPageRunner.status === "Succeeded" && !!pageDataState.value &&
                        <Tabs
                            tabs={[
                                {
                                    label: "Overview",
                                    id: "overview",
                                    content:  <PageOverviewTab
                                        state={pageDataState}
                                        setFlashbarItems={setFlashBarItems}
                                        reload={loadPageRunner.reload}
                                    />
                                },
                                {
                                    label: "Permissions",
                                    id: "permissions",
                                    content: <EntityPermissionDetail.PreloadComp id={props.id}/>
                                },
                                {
                                    label: "Live Page",
                                    id: "livePage",
                                    content: <PageLive id={props.id}/>
                                },
                                {
                                    label: "Draft",
                                    id: "draftPage",
                                    content: <PageDraftDetail.Preload
                                        pageRef={EntityFactory.toEntityRef(pageDataState.value)}
                                        pageData={pageDataState.value}
                                        setIsEditing={setIsEditing}
                                    />
                                },
                                {
                                    label: "Version History",
                                    id: "versionHistory",
                                    content: <PageVersionHistory.TableView state={pageDataState}/>
                                }
                            ]}
                            activeTabId={activeTabId}
                            onChange={(e) => {
                                if (isEditing) {
                                    window.alert("You have unsaved changes. Please save them to continue");
                                    return;
                                }
                                setActiveTabId(e.detail.activeTabId);
                                history.push(
                                    setTabURL(e.detail.activeTabId)
                                );
                            }}
                        />
                    }
                </React.Fragment>
            </SpaceBetween>
        </React.Fragment>
    );
};